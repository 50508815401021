// Default imports
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useQueryParam, StringParam } from "use-query-params";

import { TabLabels } from "./Tabs";
import TilesNew from "./TilesNew";

import { teamCompData } from "../data/components-team.data";

const Team = () => {
  const [preventScroll, setPreventScroll] = useQueryParam("prsc", StringParam);

  const compData = teamCompData();

  // Tabs
  const tabs = [
    ...compData.allWpTeamCategory.nodes
      .filter((node) => node.count > 0)
      .map((node) => {
        return { name: node.name, id: node.slug };
      }),
  ];

  // Team
  const team = compData.allWpTeam.nodes
    .map((member) => {
      return {
        order: member.menuOrder,
        key: member.id,
        coverHeader: member.title,
        coverText: member.cptTeamMember.office,
        tileText: member.cptTeamMember.description,
        coverImage: member.featuredImage?.node,
        cat: member.teamCategories.nodes.map((node) => node.slug),
      };
    })
    .sort((a, b) => {
      return Number(a.order) < Number(b.order) ? -1 : 1;
    });

  const [activeCat, setActiveCat] = useQueryParam("cat", StringParam);

  const handleTabClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveCat(id);
  };

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });

  return (
    <Container>
      {/* Tabs list */}
      <TabLabels
        className="py-2"
        buttonVariant="small,deepblue"
        labels={tabs.map((item) => ({
          label: item.name,
          active: item.id === (activeCat ? activeCat : "board"),
          onClick: (e) => handleTabClick({ event: e, id: item.id }),
        }))}
      />

      {/* Partners */}
      <TilesNew items={team} activeCat={(activeCat ? activeCat : "board")} isLoaded={isLoaded} />
    </Container>
  );
};

export default Team;
