// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const TeamHeader =  ({ description, number, numberText }) => {


  return description || number || numberText ? (
    <Row>
        <Col xs="24" lg="16">
            {description && (
                <p className="team-header__description" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                {description}
                </p>
            )}
        </Col>
        <Col xs="24" lg={{span:6, offset: 2}}>
            {number && (
                <p className="team-header__number" data-sal="slide-left" data-sal-duration="800" data-sal-delay="400">
                    {number}
                </p>
            )}
            {numberText && (
                <p className="team-header__text" data-sal="slide-left" data-sal-duration="800" data-sal-delay="400">
                    {numberText}
                </p>
            )}
        </Col>
    </Row>
  ) : (
    <></>
  );
};

export default TeamHeader;
