import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const teamCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        allWpTeam {
          nodes {
            id
            slug
            title
            menuOrder

            cptTeamMember {
              office
              description
            }

            featuredImage {
              node {
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(width: 750, height: 750, quality: 95)
                  }

                  childSvg {
                    content {
                      data
                    }
                  }
                }
              }
            }
            teamCategories {
              nodes {
                slug
              }
            }
          }
        }
        allWpTeamCategory {
          nodes {
            id
            count
            slug
            name
          }
        }
      }
    `
  );

  return data;
};

export { teamCompData };
