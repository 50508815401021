// Default imports
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import WpImage from "../components/WpImage";
import ButtonText from "./ButtonText";

const TilesNew = ({ items, activeCat, isLoaded, className }) => (
  <Row className="pt-5 tiles-n__row">
    {items
      .filter(
        (item) =>
          !activeCat || activeCat === item.cat || item.cat.includes(activeCat)
      )
      .map((item, index) => {
        const hasCoverContent = item.coverHeader || item.coverText;
        const colSizes = hasCoverContent
          ? "col-rg-12 col-xl-8"
          : "col-md-12 col-lg-8";
        return (
          <Col
            key={item.order || `${item.cat}-${index}`}
            className={`col-24 ${colSizes} cmb-4 ${isLoaded ? "" : "d-none"}`}
          >
            <TileNew
              style={{ animationDelay: `${index < 10 ? index * 0.1 : 1}s` }}
              {...item}
              className={className}
            />
          </Col>
        );
      })}
  </Row>
);

const TileNew = ({
  className,
  coverImage,
  coverHeader,
  coverText,
  tileText,
  bankingPartner,
  lists,
  ...attrs
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div
      className={`tile-n -light -large -connectivity ${
        isActive ? "-active" : ""
      } ${className}`}
      // Manage isActive
      onTouchMove={() => {
        setIsDragging(true);
      }}
      onTouchEnd={() => {
        if (!isDragging) {
          setIsActive(!isActive);
        }
      }}
      onTouchStart={(e) => {
        setIsDragging(false);
      }}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
      {...attrs}
    >
      <div className="tile-n__wrapper">
        {bankingPartner && (
          <div className="tile-n__icon">
            <div className="hexagon">
              <p className="tile-n__icon-text">
                Premium <br />{" "}
                <span className="tile-n__icon-subtext">Partner</span>
              </p>
            </div>
          </div>
        )}

        <div className="team-box__inf">
          {coverImage && (
            <WpImage
              className={`tile-n__cover-image -square`}
              image={coverImage}
            />
          )}
          {(coverHeader || coverText) && (
            <div className="tile-n__cover-content">
              <h3 className="tile-n__cover-header">{coverHeader}</h3>
              <p className="tile-n__cover-text">{coverText}</p>
            </div>
          )}
        </div>
        {lists && (
          <div className="tile-n__content">
            {lists.map(({ header, text, list, link }, index) => (
              <div key={index} className="tile-n__list text-center">
                <h6 className="tile-n__title -mb-small -no-mt">{header}</h6>
                {text && <p className="tile-n__text -default">{text}</p>}
                {list && (
                  <p className="tile-n__text">
                    {list.map(({ item }, index) => (
                      <span key={index}>
                        {item}
                        {index < list.length - 1 ? " | " : ""}
                      </span>
                    ))}
                  </p>
                )}
                {link && link.url && (
                  <ButtonText className="tile-n__button" variant="red" {...link}>
                    {link?.title}
                  </ButtonText>
                )}
              </div>
            ))}
          </div>
        )}
        {tileText && (
          <div className="tile-n__content">
            <p className="tile-n__text -long">{tileText}</p>
            {/* <h3 className="tile-n__cover-header">{coverHeader}</h3>
            <p className="tile-n__cover-text">{coverText}</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TilesNew;
