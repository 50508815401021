import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const teamPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Team {
                  teamRichContent {
                    # SECTION: Page Header
                    pageHeader {
                      title
                      text
                    }
                    additionalInfo {
                      description
                      number
                      numberText
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "teamRichContent");
};

export { teamPageTplData };
